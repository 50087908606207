import React from 'react';
import './style.scss';
import { Snackbar } from '@material-ui/core';
import { constraints } from './constraints';
import validate from 'validate.js';
import { Alert } from '@material-ui/lab';

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class ApplyNow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        email: '',
        message: '',
        profile: '',
        cv: '',
      },
      open: false,
      openError: false,
      isValidated: false,
      errors: {},
    };
  }

  handleChange = e => {
    if (e.target.name == 'cv') {
      if (e.target.files[0].size <= 4000000) {
        this.setState({
          form: {
            ...this.state.form,
            [e.target.name]: e.target.files[0],
          },
        });
      } else {
        this.setState({ openError: true });
      }
    } else
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value,
        },
      });
  };

  handleClose = reason => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleCloseError = reason => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openError: false });
  };

  handleSubmit = e => {
    let errors = validate(this.state.form, constraints);
    this.setState({ errors: errors });
    if (errors && Object.keys(errors).length != 0) {
      this.setState({ isValidated: false });
    } else {
      fetch('/', {
        method: 'POST',
        body: encode({ 'form-name': 'Apply Form', ...this.state.form }),
      })
        .then(() =>
          this.setState({
            form: {
              name: '',
              email: '',
              message: '',
              profile: '',
              cv: '',
            },
            open: true,
          }),
        )
        .catch(error => alert(error));
    }
    e.preventDefault();
  };

  render() {
    const { name, email, message, profile, cv } = this.state.form;
    const { open, openError } = this.state;
    return (
      <div className="apply-form" id="apply">
        <div className="apply-form__title">Apply to Theodo</div>
        <form
          name="Apply Form"
          method="POST"
          className="apply-form__form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="Apply Form" />
          <div className="apply-form__form__element">
            <div className="apply-form__form__element__title">Your name*</div>
            <input
              className="apply-form__form__element__input"
              placeholder="Your name"
              name="name"
              type="text"
              value={name}
              onChange={this.handleChange}
            />
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.name && (
                <span className="error-utility">
                  {this.state.errors.name[0]}
                </span>
              )}
          </div>
          <div className="apply-form__form__element">
            <div className="apply-form__form__element__title">
              Contact email*
            </div>
            <input
              className="apply-form__form__element__input"
              placeholder="you@example.com"
              name="email"
              value={email}
              onChange={this.handleChange}
            />
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.email && (
                <span className="error-utility">
                  {this.state.errors.email[0]}
                </span>
              )}
          </div>
          <div className="apply-form__form__element">
            <div className="apply-form__form__element__title">
              Linkedin profile
            </div>
            <input
              className="apply-form__form__element__input"
              placeholder="Profile"
              name="profile"
              type="text"
              value={profile}
              onChange={this.handleChange}
            />
          </div>
          <div className="apply-form__form__element">
            <div className="apply-form__form__element__title">CV</div>
            <div className="apply-form__form__element__input">
              <div className="apply-form__form__element__filename">
                {cv && cv.name}
              </div>
              <input
                className="apply-form__form__element__inputfile"
                placeholder="CV"
                name="cv"
                type="file"
                onChange={this.handleChange}
              />
              <div className="apply-form__form__element__upload">Upload</div>
            </div>
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.cv && (
                <span className="error-utility">{this.state.errors.cv[0]}</span>
              )}
          </div>
          <div className="apply-form__form__element-2">
            <div className="apply-form__form__element-2__title">
              Please present youreself in a few sentences
            </div>
            <textarea
              className="apply-form__form__element-2__textarea"
              placeholder="Type your message…."
              name="message"
              value={message}
              onChange={this.handleChange}
              type="text"
            />
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.message && (
                <span className="error-utility">
                  {this.state.errors.message[0]}
                </span>
              )}
          </div>
          <div className="apply-form__form__remark">
            By submitting this form you agree to our terms and conditions and
            our Privacy Policy which explains how we may collect, use and
            disclose your personal information including to third parties.
          </div>
          <button className="apply-form__form__button" type="submit">
            Apply now
          </button>
        </form>

        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="success"
          >
            Thank you!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={3000}
          onClose={this.handleCloseError}
        >
          <Alert onClose={this.handleCloseError} severity="error">
            File is too large
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default ApplyNow;
