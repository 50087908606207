export const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: "shouldn't be empty!",
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'is required!',
    },
    email: {
      message: 'is Invalid!',
    },
  },
  message: {
    presence: {
      allowEmpty: false,
      message: "shouldn't be empty!",
    },
  },
  cv: {
    presence: {
      allowEmpty: false,
      message: "shouldn't be empty!",
    },
  },
};
