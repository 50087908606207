import React from 'react';
import Layout from '../components/Layout';
import ApplyNow from '../components/apply-now/ApplyNow';

const ApplyPageTemplate = () => {
  return (
    <Layout>
      <ApplyNow />
    </Layout>
  );
};

export default ApplyPageTemplate;
